.circle-preloader__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.circle-preloader__hidden {
  display: none;
}

.circle-preloader {
  display: block;
  width: 50px;
  height: 50px;
  border: 4px solid #444;
  border-bottom-color: #888;

  border-radius: 50%;

  animation: spin 0.75s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.circle-preloader__description {
  color: #b6bcbf;
  text-align: center;
  font-family: "Roboto", Arial;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 24px;
}
