.footer {
  display: flex;
  justify-content: space-between;
  margin: 0;
  height: 80px;
  max-width: 1440px;
  background-color: #fff;
}

.footer__copyright {
  display: flex;
  align-items: center;
  color: #b6bcbf;
  font-family: "Roboto", Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin-left: 104px;
}

.footer__socials {
  display: flex;
  align-items: center;
  margin-right: 104px;
}

.footer__home {
  margin-right: 20px;
  color: var(--main-primary, #1a1b22);
  text-align: center;
  font-family: "Roboto", Arial;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  cursor: pointer;
}

.footer__tripleten {
  margin-left: 20px;
  margin-right: 20px;
  color: var(--main-primary, #1a1b22);
  text-align: center;
  font-family: "Roboto", Arial;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  cursor: pointer;
}

.footer__github {
  margin-left: 20px;
  margin-right: 13px;
  background: transparent url("../../images/github.svg") center no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.footer__facebook {
  margin-left: 13px;
  background: transparent url("../../images/facebook.svg") center no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.footer__link {
  text-decoration: none;
  margin: 0;
}

#home {
  grid-area: home;
}

#tripleten {
  grid-area: tripleten;
}

#github {
  grid-area: github;
}

#facebook {
  grid-area: facebook;
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .footer__copyright {
    margin-left: 40px;
  }

  .footer__socials {
    margin-right: 40px;
  }

  .footer__home {
    margin-right: 20px;
  }

  .footer__tripleten {
    margin-left: 20px;
    margin-right: 20px;
  }

  .footer__facebook {
    margin-left: 20px;
    margin-right: 12px;
  }

  .footer__github {
    margin-left: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .footer__copyright {
    margin-left: 40px;
  }

  .footer__socials {
    margin-right: 40px;
  }

  .footer__home {
    margin-right: 20px;
  }

  .footer__tripleten {
    margin-left: 20px;
    margin-right: 20px;
  }

  .footer__facebook {
    margin-left: 20px;
    margin-right: 12px;
  }

  .footer__github {
    margin-left: 12px;
  }
}

@media screen and (min-width: 680px) and (max-width: 767px) {
  .footer__copyright {
    margin-left: 16px;
  }

  .footer__socials {
    margin-right: 16px;
  }

  .footer__home {
    margin-right: 20px;
  }

  .footer__tripleten {
    margin-left: 20px;
    margin-right: 20px;
  }

  .footer__facebook {
    margin-left: 20px;
    margin-right: 12px;
  }

  .footer__github {
    margin-left: 12px;
  }
}

@media screen and (min-width: 580px) and (max-width: 679px) {
  .footer__copyright {
    margin-left: auto;
    margin-right: auto;
  }

  .footer__socials {
    margin-left: auto;
    margin-right: auto;
  }

  .footer__home {
    margin-right: 10px;
  }

  .footer__tripleten {
    margin-left: 10px;
    margin-right: 10px;
  }

  .footer__facebook {
    margin-left: 10px;
    margin-right: 6px;
  }

  .footer__github {
    margin-left: 6px;
  }
}

@media screen and (min-width: 320px) and (max-width: 579px) {
  .footer {
    display: grid;
    grid-template-areas:
      "socials"
      "copyright";
    height: 176px;
    max-width: 579px;
    justify-content: center;
  }

  .footer__copyright {
    width: 288px;
    grid-area: copyright;
    margin-left: 16px;
  }

  .footer__socials {
    grid-area: socials;
    display: grid;
    grid-template-areas:
      "home github facebook"
      "tripleten . .";
    width: 288px;
    margin-right: 16px;
    margin-left: 16px;
  }

  .footer__home {
    padding: 0;
    text-align: start;
    width: 200px;
    margin: 0;
  }

  .footer__tripleten {
    padding: 0;
    text-align: start;
    width: 200px;
    margin: 0;
  }

  .footer__github {
    padding: 0;
    margin: 0;
    justify-self: center;
  }

  .footer__facebook {
    padding: 0;
    margin-left: 24px;
    justify-self: center;
  }
}
