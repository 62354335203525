.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__container {
  position: relative;
  background-color: #fff;
  border-radius: 16px;
}

.modal__container-signin {
  width: 430px;
  height: 380px;
}

.modal__container-signup {
  width: 430px;
  height: 457px;
}

.modal__container-success {
  width: 430px;
  height: 182px;
}

.modal__form {
  width: 358px;
  margin-left: 36px;
}

.modal__title {
  color: var(--main-primary, #1a1b22);
  font-family: "Roboto", Arial;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.2;
  margin-left: 36px;
  margin-top: 34px;
  margin-bottom: 22px;
}

.modal__close {
  position: absolute;
  top: -45px;
  right: -45px;
  background: transparent url("../../images/close.svg") center no-repeat;
  width: 40px;
  height: 40px;
  border: none;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

.modal__close:hover {
  opacity: 0.6;
}

.modal__label {
  color: #2f71e5;
  font-family: "Inter", Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
}

.modal__label:last-of-type {
  margin-top: 15px;
}

.modal__input {
  width: 100%;
  padding: 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: #000;
  font-family: "Inter", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

.modal__btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__submit-btn {
  margin-top: 39px;
  margin-bottom: 16px;
  width: 358px;
  height: 64px;
  background: #2f71e5;
  color: #fff;
  text-align: center;
  font-family: "Roboto", Arial;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.modal__submit-btn:hover {
  opacity: 0.6;
}

.modal__submit-btn_disabled {
  background: #e6e8eb;
  color: #b6bcbf;
}

.modal__alt-btn {
  color: #000;
  text-align: center;
  font-family: "Inter", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  border: none;
  background: transparent;
  cursor: pointer;
}

.modal__alt-text {
  color: #2f71e5;
  font-family: "Inter", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

.modal__signin-btn {
  color: #2f71e5;
  font-family: "Inter", Arial;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-left: 36px;
  padding: 0;
}

.modal__error {
  color: #f00;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3px;
}

.modal__error-unavailable {
  color: #f00;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: 17px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .modal__container {
    padding: 0;
  }

  .modal__container-signin {
    max-width: 430px;
  }

  .modal__container-signup {
    max-width: 430px;
  }

  .modal__title {
    width: 158px;
    margin: 34px 0 22px 36px;
  }

  .modal__close {
    width: 40px;
    height: 40px;
    top: -36px;
    right: -36px;
  }

  .modal__form {
    max-width: 358px;
    margin: 0 36px 28px;
  }

  .modal__input {
    width: 100%;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .modal__container {
    padding: 0;
  }

  .modal__container-signin {
    max-width: 430px;
  }

  .modal__container-signup {
    max-width: 430px;
  }

  .modal__title {
    width: 158px;
    margin: 34px 0 22px 36px;
  }

  .modal__close {
    width: 40px;
    height: 40px;
    top: -36px;
    right: -36px;
  }

  .modal__form {
    max-width: 358px;
    margin: 0 36px 28px;
  }

  .modal__input {
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .modal {
    align-items: normal;
  }

  .modal__container {
    padding: 0;
    top: 56px;
  }

  .modal__container-signin {
    max-width: 320px;
    height: 560px;
  }

  .modal__container-signup {
    max-width: 320px;
    height: 560px;
  }

  .modal__container-success {
    max-width: 320px;
    height: 560px;
    align-items: center;
  }

  .modal__form {
    max-width: 288px;
    margin: 0 16px 254px;
  }

  .modal__title {
    margin: 16px 0 18px 17px;
  }

  .modal__close {
    width: 24px;
    height: 24px;
    top: -40px;
    right: 16px;
  }

  .modal__input {
    width: 288px;
  }

  .modal__submit-btn {
    width: 288px;
  }

  .modal__signin-btn {
    margin-left: 18px;
  }
}
