.search {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.search__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 80px 416px 88px;
}

.search__title {
  color: #fff;
  font-family: "Roboto Slab", Arial;
  font-size: 60px;
  font-weight: 400;
  line-height: 1.2;
  width: 608px;
  height: 128px;
  margin: 0 0 32px;
}

.search__explaination {
  color: #fff;
  font-family: "Roboto", Arial;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  width: 608px;
  margin: 0;
}

.search__form {
  display: flex;
  max-width: 1440px;
  width: 1425px;
}

.search__input {
  color: #000;
  font-family: "Roboto", Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  width: 608px;
  min-width: 608px;
  height: 66px;
  fill: #fff;
  stroke-width: 1px;
  stroke: var(--simulator-gray-divider, #d1d2d6);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 100px;
  border: none;
  text-indent: 24px;
  margin-left: 416px;
  margin-right: 416px;
  padding: 0;
}

.search__button {
  position: relative;
  right: 584px;
  width: 168px;
  min-width: 168px;
  height: 66px;
  border-radius: 100px;
  background: #2f71e5;
  box-shadow: 0px 5px 15px 0px rgba(14, 26, 57, 0.2);
  border: none;
  color: var(--main-white, #fff);
  text-align: center;
  font-family: "Roboto", Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  padding: 0;
}

.search__input::placeholder {
  color: #b6bcbf;
  font-family: "Roboto", Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

@media screen and (min-width: 1361px) and (max-width: 1439px) {
  .search {
    align-items: center;
    margin-bottom: 48px;
  }

  .search__form {
    width: 1348px;
  }

  .search__input {
    width: 608px;
    min-width: 608px;
    height: 66px;
    margin-left: 374px;
    margin-right: 374px;
  }

  .search__button {
    width: 168px;
    min-width: 168px;
    height: 66px;
    right: 542px;
  }
}

@media screen and (min-width: 1261px) and (max-width: 1360px) {
  .search {
    align-items: center;
    margin-bottom: 48px;
  }

  .search__form {
    width: 1246px;
  }

  .search__input {
    width: 608px;
    min-width: 608px;
    height: 66px;
    margin-left: 320px;
    margin-right: 320px;
  }

  .search__button {
    width: 168px;
    min-width: 168px;
    height: 66px;
    right: 488px;
  }
}

@media screen and (min-width: 1124px) and (max-width: 1260px) {
  .search {
    align-items: center;
    margin-bottom: 48px;
  }

  .search__title {
    font-size: 36px;
    width: 385px;
    height: 80px;
  }

  .search__explaination {
    width: 452px;
  }

  .search__form {
    width: 1109px;
  }

  .search__input {
    width: 460px;
    min-width: 460px;
    height: 66px;
    margin-left: 318px;
    margin-right: 160px;
  }

  .search__button {
    width: 160px;
    min-width: 160px;
    height: 66px;
    right: 320px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1123px) {
  .search {
    align-items: center;
    margin-bottom: 48px;
  }

  .search__container {
    margin: 30px 160px 48px;
  }

  .search__title {
    font-size: 36px;
    width: 385px;
    height: 80px;
    margin-bottom: 16px;
  }

  .search__explaination {
    width: 452px;
  }

  .search__form {
    width: 1009px;
  }

  .search__input {
    width: 460px;
    min-width: 460px;
    height: 66px;
    margin-left: 280px;
    margin-right: 160px;
  }

  .search__button {
    width: 160px;
    min-width: 160px;
    height: 66px;
    right: 320px;
  }
}

@media screen and (min-width: 968px) and (max-width: 1023px) {
  .search {
    align-items: center;
    margin-bottom: 48px;
  }

  .search__container {
    margin: 30px 160px 48px;
  }

  .search__title {
    font-size: 36px;
    width: 385px;
    height: 80px;
    margin-bottom: 16px;
  }

  .search__explaination {
    width: 452px;
  }

  .search__form {
    width: 953px;
  }

  .search__input {
    width: 448px;
    min-width: 448px;
    height: 56px;
    margin-left: 261px;
    margin-right: 160px;
  }
  .search__button {
    width: 160px;
    min-width: 160px;
    height: 56px;
    right: 320px;
  }
}

@media screen and (min-width: 868px) and (max-width: 967px) {
  .search {
    align-items: center;
    margin-bottom: 48px;
  }

  .search__container {
    margin: 30px 160px 48px;
  }

  .search__title {
    font-size: 36px;
    width: 385px;
    height: 80px;
    margin-bottom: 16px;
  }

  .search__explaination {
    width: 452px;
  }

  .search__form {
    width: 853px;
  }

  .search__input {
    width: 448px;
    min-width: 448px;
    height: 56px;
    margin-left: 210px;
    margin-right: 160px;
  }

  .search__button {
    width: 160px;
    min-width: 160px;
    height: 56px;
    right: 320px;
  }
}

@media screen and (min-width: 768px) and (max-width: 867px) {
  .search {
    margin-bottom: 48px;
  }

  .search__container {
    margin: 38px 160px 48px;
  }

  .search__title {
    font-size: 36px;
    width: 426px;
    height: 80px;
    margin-bottom: 16px;
  }

  .search__explaination {
    width: 452px;
  }

  .search__form {
    width: 753px;
  }

  .search__input {
    width: 448px;
    min-width: 448px;
    height: 56px;
    margin-left: 160px;
    margin-right: 160px;
  }

  .search__button {
    width: 160px;
    min-width: 160px;
    height: 56px;
    right: 320px;
  }
}

@media screen and (min-width: 681px) and (max-width: 767px) {
  .search {
    align-items: center;
    margin-bottom: 48px;
  }

  .search__container {
    margin: 30px 160px 48px;
  }

  .search__title {
    font-size: 36px;
    width: 385px;
    height: 80px;
    margin-bottom: 16px;
  }

  .search__explaination {
    width: 452px;
  }

  .search__form {
    width: 667px;
  }

  .search__input {
    width: 448px;
    min-width: 448px;
    height: 56px;
    margin-left: 114px;
    margin-right: 160px;
  }

  .search__button {
    width: 160px;
    min-width: 160px;
    height: 56px;
    right: 320px;
  }
}

@media screen and (min-width: 580px) and (max-width: 680px) {
  .search {
    align-items: center;
    margin-bottom: 48px;
  }

  .search__container {
    margin: 30px 160px 48px;
  }

  .search__title {
    font-size: 36px;
    width: 385px;
    height: 80px;
    margin-bottom: 16px;
  }

  .search__explaination {
    width: 452px;
  }

  .search__form {
    width: 565px;
  }

  .search__input {
    width: 448px;
    min-width: 448px;
    height: 56px;
    margin-left: 68px;
    margin-right: 160px;
  }

  .search__button {
    width: 160px;
    min-width: 160px;
    height: 56px;
    right: 320px;
  }
}

@media screen and (min-width: 320px) and (max-width: 579px) {
  .search {
    align-items: center;
    margin-bottom: 48px;
  }

  .search__container {
    margin: 30px 16px 122px;
  }

  .search__title {
    font-size: 36px;
    width: 285px;
    height: 80px;
    margin-bottom: 16px;
  }

  .search__explaination {
    width: 288px;
    height: 72px;
  }

  .search__form {
    display: flex;
    flex-direction: column;
    width: 305px;
  }

  .search__input {
    width: 288px;
    min-width: 288px;
    height: 56px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
  }

  .search__button {
    position: static;
    width: 288px;
    height: 56px;
    margin-left: auto;
    margin-right: auto;
  }
}
