.main {
  display: flex;
  flex-direction: column;
}

.main__results {
  background: #f5f6f7;
  display: flex;
  flex-direction: column;
}
